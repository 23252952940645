export default {
    MESH_EVENTS : Object.freeze({
        GET_TOKEN : "GET_TOKEN",
        PRINT_SAL: "PRINT_SAL",
        SET_SAL_FORMAT: "SET_SAL_FORMAT",
        DESTROY: "DESTROY",
        AVERY_SCAN: "AVERY_SCAN",
        SBL_SOURCE_SCAN: "SBL_SOURCE_SCAN",
        SBL_DESTINATION_SCAN: "SBL_DESTINATION_SCAN",
        ZEBRA_CREATE_CONNECTION: "ZEBRA_CREATE_CONNECTION",
        ZEBRA_CLOSE_CONNECTION: "ZEBRA_CLOSE_CONNECTION",
        ZEBRA_PRINT_LABEL: "ZEBRA_PRINT_LABEL",
        ZEBRA_PRINTER_STATUS: "ZEBRA_PRINTER_STATUS",
        ZEBRA_PRINTER_RESOLUTION: "ZEBRA_PRINTER_RESOLUTION",
        GET_AVERY_CONNECTION_STATUS: "GET_AVERY_CONNECTION_STATUS",
        AVERY_CHANGE_PRINTER: "AVERY_CHANGE_PRINTER",
        ZEBRA_CREATE_USB_CONNECTION: "ZEBRA_CREATE_USB_CONNECTION",
        AVERY_DISCONNECT:"AVERY_DISCONNECT"
    })
}