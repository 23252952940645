import Analytics from "@aws-amplify/analytics";
import Auth from "@aws-amplify/auth";
import {dolphinEventsBuilder, dolphinMetricsBuilder} from "../model/DolphinAnalyticEvents";
import AnalyticEventKeys from "./AnalyticEventKeys";
import {getDolphinAttributes} from "./ModuleAnalyticsUtility";
import Logger from "../utils/Logger";
import {wrapWithTimeout} from "../utils/CommonUtils"

export default {
    region: "",
    async initializeAnalytics(region){
        this.region = region;
        const authConfig = {
            identityPoolId: 'us-east-1:6b7aa5e1-583d-4a91-8d21-c2d3a337ade0',
            region: 'us-east-1'
        };

        const pinpointConfig = {
            autoSessionRecord: false,
            AWSPinpoint: {
                // Amazon Pinpoint App Client ID
                appId: '5e0cafc38eb2450da61e6ffadf092462',
                // Amazon service region
                region: 'us-east-1',
                mandatorySignIn: false,
                bufferSize: 100,
                resendLimit: 5
            }
        };
        Auth.configure(authConfig);
        Analytics.configure(pinpointConfig);

    },
    executeAPIAnalytics(module, startTime, failure) {

        const dolphinAttributes = getDolphinAttributes(this.region);
        dolphinAttributes.setModule(module)
            .setDescription(AnalyticEventKeys.Modules.PWA)
            .build();

        const dolphinMetrics = new dolphinMetricsBuilder()
            .setDuration(Date.now() - startTime)
        if (failure === true) {
            dolphinMetrics.setFailures()
                .build();
        }

        const dolphinAnalytics = new dolphinEventsBuilder()
            .setName(module)
            .setAttributes(dolphinAttributes)
            .setMetrics(dolphinMetrics)
            .build();
        Analytics.record(dolphinAnalytics);
    },
    processAnalytics(startTime, description, response, module, scanContent, description1,
                     eventName, failure, routeCode, immediate) {

        const dolphinAttributes = getDolphinAttributes(this.region);
        dolphinAttributes.setModule(module)
            .setDescription(description)
            .setScanContent(scanContent)
            .setDescription1(description1)

        if (routeCode) {
            dolphinAttributes.setRouteCode(routeCode)
        }

        dolphinAttributes.build();

        const dolphinMetrics = new dolphinMetricsBuilder()
            .setDuration(Date.now() - startTime)

        if (failure === true) {
            dolphinMetrics.setFailures()
                .build();
        }

        const dolphinAnalytics = new dolphinEventsBuilder()
            .setName(eventName)
            .setAttributes(dolphinAttributes)
            .setMetrics(dolphinMetrics)
            .setImmediate(immediate)
            .build();

        return Analytics.record(dolphinAnalytics);
    },

    /**
     * This waits for pinpoint API to publish analytic till "publishTimeout" milliseconds.
     * This helps in adding await on this async method with timeout.
     * Note: The original pinpoint promise will not be terminated even after timeout
     * Note: The immediate flag will cause the event to emit immediately and hence it will not be sent into buffer,
     * due to this event will be lost if API call fails with non-retryable error, which otherwise would have retried.
     *
     * @param startTime
     * @param description
     * @param response
     * @param module
     * @param scanContent
     * @param description1
     * @param eventName
     * @param failure
     * @param routeCode
     * @param publishTimeout
     * @return {Promise<void>}
     */
    async processAnalyticsWithTimeout(startTime, description, response, module, scanContent, description1,
                                      eventName, failure, routeCode, publishTimeout) {
        let publishPromise = this.processAnalytics(startTime, description, response, module, scanContent, description1,
            eventName, failure, routeCode, true);

        try {
            await wrapWithTimeout(publishPromise, publishTimeout);
        } catch (e) {
            Logger.log.error(`Error while publishing event ${eventName}: ${e}`);
        }
    }
}
